<template>
  <div class="flex justify-center items-center w-screen h-screen">
    <div class="-mt-14">
      <div class="text-center text-xl font-normal text-gray-700 pt-8">
        Sincronizando com a cabine
      </div>
      <div class="pa-4">
        <div class="flex justify-center items-center">
          <div
            class="animate-spin rounded-full h-20 w-20 border-0 border-t-2 border-pink-400 mt-10 p-5 border-dotted bg-pink-50"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "@/service/firebase";
import etapa from "@/constants/etapaCompra";
import { removeCodeInList } from "@/service/firebase.js";

export default {
  beforeMount() {
    this.verificarExistenciaEStatus();
  },
  methods: {
    verificarParamentroDeRota() {
      if (this.$route.params.id && this.$route.params.id.length > 5) {
        return true;
      } else {
        console.error("NÃO TEM PERMISSÃO");
        return false;
      }
    },
    async verificarExistenciaEStatus() {
      if (!this.verificarParamentroDeRota()) {
        this.$router.push("/sem-autorizacao");
        return false;
      }
      const compra_id = this.$route.params.id;
      const docRef = doc(db, "compras", `${compra_id}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let statusEtapa = docSnap.data().etapa;
        this.verificaEtapa(statusEtapa, docRef, compra_id, docSnap);
      } else {
        console.error("compra não existe");
        this.$router.push("/sem-autorizacao");
      }
    },
    async verificaEtapa(etapaPayload, docRef, compra_id, docSnap) {
      if (etapaPayload == etapa.SCAN_QR_CODE) {
        localStorage.setItem("compra_id", compra_id);
        this.$store.commit("compra/setCompra", docSnap.data());
        await setDoc(docRef, { etapa: etapa.UPLOAD }, { merge: true });
        removeCodeInList();
      } else {
        console.error("compra não esta em SCAN");
        this.$router.push("/sem-autorizacao");
      }
    },
  },
};
</script>

<style></style>
